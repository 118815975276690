
.accordion {
  padding: 0;
  list-style: none;
  border-bottom: 1px solid var(--color-text);

  &__header {
    outline: unset;
    position: relative;

    padding-top: get-size(300);
    padding-right: get-size(900);
    padding-bottom: get-size("base");
    padding-left: 0;
    text-align: left;
    text-transform: capitalize;

    border: none;
    border-top: 1px solid var(--color-text);
    border-radius: 0;
    margin: 0;

    background: transparent;
    line-height: inherit;
    cursor: pointer;
    white-space: normal;
    outline: unset;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -1px;
      background-color: var(--color-text);
      height: 2px;
      width: get-size(500);
      right: get-size(300);
      border-radius: 1px;
    }

    &::after {
      transform: rotate(-90deg);
    }

    &[aria-expanded='true'] {
      &::before {
        transform: rotate(-180deg);
      }

      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  &__panel {
    margin: 0;
    max-height: 75vh;
    overflow: hidden;
    transition: all 0.3s ease-out;
    max-height: 0;
    transform: none;

    &.is-hidden {
      max-height: 0 !important;
      visibility: hidden;
    }
  }
}