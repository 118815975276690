.survey {
  position: relative;
  z-index: 1;
  margin-top: get-size("max");

  // mobile
  &:not(.survey--expanded) {
    border-collapse: separate;
    border-spacing: 0 get-size(500);
    margin-top: calc(-2 * #{get-size(500)});

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-direction: column;
    }

    tr {
      border: 2px solid;
      padding: get-size(300);
      margin-bottom: get-size(700);
    }

    th {
      padding: get-size(500);
      font-size: get-size(700);
    }

    td {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: right;
      padding: get-size(500);
    }

    .survey__label {
      font-weight: bold;
      text-align: left;
    }
  }

  th:not(:first-of-type) {
    transform: rotate(-90deg);
    transform-origin: top left;
    white-space: nowrap;
    text-transform: uppercase;
    width: 7em;
    min-width: 70px;
    text-align: left;

    @include media-query('lg') {
      transform: rotate(-35deg);
      transform-origin: bottom left;
    }
  }

  // desktop
  &--expanded {

    // detect when the table needs to switch from the mobile layout to an expanded one - used in JS
    &::before {
      display: none;
      content: 'collapsed';

      @include media-query('lg') {
        content: 'expanded';
      }
    }

    thead {
      td {
        position: relative;
        z-index: 10;
      }
    }

    .survey__label { // hide inline labels
      display: none;
    }
  }

  &-form {
    padding: get-size(600) 0;

    @include media-query('lg') {
      border: 2px solid;
      padding: get-size(600);
    }
  }

  .js & {
    opacity: 0;

    &--loaded {
      opacity: 1;
    }
  }
}