.btn {
    display: inline-block;
    border: none;
    background-color: var(--color-text-glare);
    color: var(--color-bg-glare);
    padding: get-size(300) get-size('base');
    line-height: 1;
    margin: 0;
    text-decoration: none;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;

    // If this doesn’t have a 'bg-' prefixed class, 
    // apply a default
    // &:not([class*='bg-']) {
    //   background: #efefef;
    // }
}

.btn:hover,
.btn:focus {
    filter: brightness(1.2);
}

.btn:focus:hover {
    outline: none;
}

.btn:focus:not(:hover) {
    outline: 1px solid var(--color-action-text);
    outline-offset: -4px;
}

.btn:active {
    transform: scale(0.99);
}

.btn:disabled {
    opacity: 0.5;
}

.btn-link {
    display: inline;
    padding: 0;
    border: 0;
    font: inherit;
    text-decoration: underline;
    cursor: pointer;
    background: transparent;
    color: currentColor;

    -webkit-appearance: none;
}