/* FactDeck-400 - Regular  */
@font-face {
  font-family: 'Feature Deck Web';
  src: url('../assets/fonts/FeatureDeck-Regular-Web.woff2') format('woff2'),
       url('../assets/fonts/FeatureDeck-Regular-Web.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Feature Deck Web";
  src: url('../assets/fonts/FeatureDeck-RegularItalic-Web.woff2') format("woff2"),
          url('../assets/fonts/FeatureDeck-RegularItalic-Web.woff2') format("woff");
  font-weight: 400;
  font-style: italic;
}

/* Ace-Attorney - Regular */
@font-face {
  font-family: 'Ace Attorney';
  src: url('../assets/fonts/Ace-Attorney.woff2') format('woff2'),
       url('../assets/fonts/Ace-Attorney.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  // Strip the unit off the size ratio to generate a line height
  $line-height: get-size(600);
  line-height: $line-height / ($line-height * 0 + 1);
  letter-spacing: 0.03em;

  @include apply-utility('font', 'serif');
}

h1,
h2,
h3 {
  @include apply-utility('font', 'serif');
  font-weight: normal;
}

h1 {
  font-size: get-size(700);
}

h2 {
  font-size: get-size(600);
}

h3 {
  font-size: get-size(500);
}

@include media-query('md') {
  h1 {
    font-size: get-size(900);
  }

  h2 {
    font-size: get-size(800);
  }

  h3 {
    font-size: get-size(700);
  }
}

h4,
h5,
h6 {
  font-weight: normal;
}