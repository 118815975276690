// Config items
$site-head__text-size: 5rem; //get-size("base");
$site-head__text-size--md: get-size(500);
$site-head__text-size--lg: get-size(700);
$site-head__space: get-size("max");
$site-head__logo-size: $site-head__text-size;
$site-head__logo-size--md: $site-head__text-size--md;
$site-head__logo-size--lg: $site-head__text-size--lg;
$site-head__logo-scale: 4; // 4
$site-head__logo-scale--md: 7; // 5
$site-head__logo-scale--lg: 8; // 6
$site-head__grid-col-gap: 2em; //($site-head__logo-scale * 1.15) * 1em;
$site-head__grid-col-gap--md: ($site-head__logo-scale--md * 1.5) * 1em;
$site-head__grid-col-gap--lg: ($site-head__logo-scale--lg * 2.5) * 1em; //2

.site-head {
  --logo-size: #{$site-head__logo-size};
  --logo-scale: #{$site-head__logo-scale};
  --grid-col-gap: #{$site-head__grid-col-gap};

  padding-top: get-size(500);
  padding-bottom: get-size(500);

   @include media-query('md') { 
    --logo-size: #{$site-head__logo-size--md};
    --logo-scale: #{$site-head__logo-scale--md};
    --grid-col-gap: #{$site-head__grid-col-gap--md};
    --head-space: #{$site-head__space * 2};

    position: sticky;
    top: 0;
    z-index: 1;
    margin-bottom: var(--head-space);
    padding-top: get-size(700);
    padding-bottom: get-size(700);
  }

  @include media-query('lg') {
    --logo-size: #{$site-head__logo-size--lg};
    --logo-scale: #{$site-head__logo-scale--lg};
    --grid-col-gap: #{$site-head__grid-col-gap--lg};
    --head-space: #{$site-head__space * 3};
  }

  &__inner {
    display: grid;
    grid-template-columns: var(--logo-size) 1fr var(--logo-size);
    grid-gap: get-size('base') var(--grid-col-gap);

    @include media-query('md') {
      grid-gap: var(--grid-col-gap);
    }

    > svg {
      height: var(--logo-size);
      width: auto;
      transition: transform .3s ease-out;

      @include media-query('md') {
        transform: scale(var(--logo-scale));
      }

      .scale-down & {
        @include media-query('md') {
          transform: scale(calc(var(--logo-scale) / 2));
        }
      }

      &:first-of-type {
        grid-column: 1;

        @include media-query('md') {
          transform-origin: top left;
        }
      }

      &:last-of-type {
        grid-column: 3;

        @include media-query('md') {
          transform-origin: top right;
        }
      }
    }
  }

  &__site-name {
    grid-column: 1 /span 3;
    grid-row: 2;

    transition: transform .3s ease-out;
    transform-origin: top center;

    @include media-query('md') {
      grid-column: 2;
      grid-row: initial;
    }
  }
}