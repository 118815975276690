/**
 * FLOW UTILITY
 * Add space between sibling elements in a (default selector) .sf-flow parent. 
 * The default space value is 1em which will be the font-size of the element that
 * has the margin-top applied to it. The `flow-space` custom property means that the 
 * cascade can be utilised if needed. 
 * Block and prefix can be configured below.
 */

// Config items
$flow__space: 1em;
$flow__enabled: get-config-value('flow', 'utilities');
$flow__prefix: $psf-util-prefix;
$flow__block: 'flow';

// Block starts if enabled in your `$psf-config`
@if ($flow__enabled == 'on') {
  #{ '.' + $flow__prefix + $flow__block } {
    --flow-space: #{$flow__space};
  }

  #{ '.' + $flow__prefix + $flow__block } > * + * {
    margin-top: $flow__space; // Fallback for if var not available
    margin-top: var(--flow-space);
  }
}