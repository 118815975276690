.noise-bg {
  pointer-events: none;
  opacity: 0.35; // control the noise opacity
  filter: grayscale(100%); // convert to grayscale colors

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: url(#noise-bg);
  }
}