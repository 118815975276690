:root {
  --redaction-intensity: -0.333em;
}

p del {
  margin-right: 0.333ch;
}

del {
  text-decoration: none;
}

.word {
  position: relative;

  &::before,
  &::after {
    content: "xx";
    position: absolute;
    left: -0.4ch;
    width: 100%;
    letter-spacing: var(--redaction-intensity);
    color: var(--color-text);
  }
}

.corrected {
  line-height: 0.9;
  // align-self: end;
  text-align: center;

  &--left {
    @include media-query('md') {
      text-align: right;
    }
  }

  &--right {
    position: relative;

    @include media-query('md') {
      text-align: left;

      p {
        right: 0;
      }
    }
  }

  p {
    margin-top: 0;

    @include media-query('md') {
      position: absolute;
      white-space: nowrap;
    }
  }
}