.intro {
  &__body {
    background: var(--color-theme-highlight-block);
    mix-blend-mode: multiply;
  }

  &__summary {
    --flow-space: #{get-size(500)};

    margin: 0 auto;

    a {
      color: currentColor;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__heading {
    max-width: 40ex;
    color: var(--color-theme-feature-text);

    &--compact {
      max-width: 20ex;
    }
  }

  &--keeper {
    p:last-of-type {
      --flow-space: #{get-size(800)};
    }
  }
}