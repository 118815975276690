/**
 * APPLY UTILITY MIXIN
 * Grab the property and value of one of the $psf-config utilities
 * that the generator will generate a class for. 
 *
 * @param {string} $key - The configured utility’s key
 * @param {string} $value-key - The value key that you are looking for within the utilty
 */
@mixin apply-utility($key, $value-key) {
    $utility: map-get($psf-config, $key);
    $property: map-get($utility, 'property');
    $items: map-get($utility, 'items');
    $item-value: map-get($items, $value-key);

    @if ($property and $item-value) {
        #{ $property }: #{$item-value};
    } @else {
        @if ($property) {
            @warn #{'Value: ‘' + $value-key + '’ doesn’t exist in ‘' + $key + '’' };
        }

        @if ($item-value) {
            @warn #{ 'Utility ‘' + $key + '’ doesn’t exist' };
        }
    }
}