// Config items
$toast__gutter: get-size(500);
$toast__padding-x: get-size(600);
$toast__padding-y: get-size(300);

.toast {
  position: fixed;
  // position: sticky;
  bottom: $toast__gutter * 2;
  left: $toast__gutter;

  background-color: var(--color-bg);
  padding-top: $toast__padding-y;
  padding-bottom: $toast__padding-y;
  padding-left: $toast__padding-x;
  padding-right: $toast__padding-x;
  border-radius: 2px;
  box-shadow: 0px 0px 0 3px rgba(0, 0, 0, 0.01), 0 0.3px 0.4px rgba(0, 0, 0, 0.025),
    0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
  color: var(--color-text);

  transition: .2s;
  transform: translateY(16px);
  transform-origin: bottom left;
  
  // hide element
  opacity: 0;
  visibility: hidden;

  &--is-visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}