/**
 * ICON UTILITY
 * A handy little utility that by default, sets your icons to 1em, width and height. 
 * This means that the icon will scale with its parent's font size.
 * You can change the base size, class prefix and block name below.
 */

// Config items
$icon__size: 1em !default;
$icon__enabled: get-config-value('icon', 'utilities') !default;
$icon__prefix: $psf-util-prefix !default;
$icon__block: 'icon';

// Block starts if enabled in your `$config`
@if ($icon__enabled == 'on') {
  #{ '.' + $icon__prefix + $icon__block } {
    display: inline-block;
    vertical-align: baseline;
    fill: currentcolor;
    width: $icon__size;
    height: $icon__size;
  }
}