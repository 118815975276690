// Config items
$scale__container-height--sm: 80vh;
$scale__axis-height: 2px;
$scale__icon-size: map-get($metrics, 'scale-dot');
$scale__offset: $scale__icon-size * 2;
$scale__offset--md: $scale__icon-size * 4;
$scale__grid-gap: map-get($metrics, 'grid-gap') / 2;
$scale__flow-space: get-size("max") * 3.5;

.scale {
  --grid-col-gap: #{$scale__grid-gap};
  --flow-space: #{$scale__flow-space};
  --scale-offset: #{$scale__offset};

  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: auto 1fr auto;
  grid-row-gap: calc(var(--grid-col-gap) + var(--scale-offset));
  height: $scale__container-height--sm;
  // seems to be occaisional issue with this
  // mix-blend-mode: multiply;

  @include media-query('md') {
    --scale-offset: #{$scale__offset--md};

    grid-template-columns: auto 1fr auto;
    grid-column-gap: calc(var(--grid-col-gap) + var(--scale-offset));
    grid-template-rows: unset;
    grid-row-gap: unset;
    height: initial;
    width: 100%;
  }

  &__label {
    text-transform: uppercase;
    color: var(--color-text);

    display: flex;
    flex-direction: column-reverse;

    @include media-query('md') {
      display: block;
      flex-direction: unset;
    }

    &:first-of-type {
      grid-area: 1 / 1 / 2 / 2;

      @include media-query('md') {
        grid-area: unset;
      }
    }

    &:last-of-type {
      grid-area: 3 / 1 / 4 / 2;

      @include media-query('md') {
        grid-area: unset;
      }
    }

    > div {
      align-self: end;
    }
  }

  &__axis {
    position: relative;
    list-style: none;
    // margin: 0;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 0;
    background-color: var(--color-text);
    display: flex;
    grid-area: 2 / 1 / 3 / 2;
    width: $scale__axis-height;
    height: 100%;
    flex-direction: column;
    box-shadow: 0 var(--scale-offset) 0 var(--color-text), 0 (calc(var(--scale-offset) * -1)) var(--color-text);

    @include media-query('md') {
      width: 100%;
      height: $scale__axis-height;
      box-shadow: var(--scale-offset) 0 0 var(--color-text), calc(var(--scale-offset) * -1) 0 0 var(--color-text);
      align-items: center;
      flex-direction: row;
      grid-area: unset;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: $scale__icon-size;
      height: $scale__icon-size;
      background-size: cover;
      transform: translateX(calc(-50% + #{$scale__axis-height / 2})) rotate(90deg);

      @include media-query('md') {
        transform: translateX(0) rotate(0);
      }
    }

    &::before {
      background-image: var(--arrow-head-left-light);
      top: calc(var(--scale-offset) * -1);

      @include media-query('md') {
        top: initial;
        left: calc(var(--scale-offset) * -1);;
      }
    }

    &::after {
      background-image: var(--arrow-head-right-light);
      bottom: calc(var(--scale-offset) * -1);;

      @include media-query('md') {
        bottom: initial;
        right: calc(var(--scale-offset) * -1);;
      }
    }
  }

  &__point {
    position: absolute;
    transform-origin: ($scale__icon-size / 2) 50%;
    top: var(--left);
    transform: translateX(calc(-#{$scale__icon-size} / 2 + #{$scale__axis-height} / 2))
    translateY(-50%) rotate(-35deg);


    @include media-query('md') {
      left: var(--left);
      transform: translateX(-$scale__icon-size / 2) rotate(-35deg);
      top: initial;
    }

    .holding-page & {
      transform: translateX(-$scale__icon-size / 2) rotate(-35deg) scale(2);

      @include media-query('md') {
        transform: translateX(-$scale__icon-size / 2) rotate(-35deg) scale(2.5);
      }
    }
  }

  &__link {
    text-decoration: none;
    color: inherit;

    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: var(--grid-col-gap);

    &:hover,
    &.active {
      .scale__name {
          background-color: var(--color-theme-highlight);
          box-shadow: 0px 0px 0 3px var(--color-theme-highlight), 0 0.3px 0.4px rgba(0, 0, 0, 0.025),
      0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
          .bg-dark & {
            color: var(--color-bg);
          }
      }
    }
  }

  &__name {
    white-space: nowrap;
    line-height: 1;
    color: var(--color-text);
  }

  &__dot {
    height: $scale__icon-size;
    width: $scale__icon-size;
    background-color: var(--color-text);
    border-radius: 50%;
    align-self: center;
  }
}