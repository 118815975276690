.post {
  color: var(--color-text);
  background-color: var(--color-bg);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: initial;

    h1 {
      @include apply-utility('measure', 'compact');
    }
  }

  &__project-title {
    font-size: get-size(700) * 4;

    @include media-query('md') {
      font-size: get-size(900) * 5;
    }
  }

  &__body {
    --flow-space: #{get-size(800)};

    /**
     * Generic HTML styles 
     */
    h2 + *,
    h3 + * {
      --flow-space: #{get-size(500)};
    }

    h2,
    h3 {
      position: relative;
      /*display: flex;*/

      @include apply-utility('leading', 'tight');
    }

    a:not([class]) {
      color: var(--color-dark);
      position: relative;
      display: inline-block;
      background: var(--color-theme-highlight);
      padding: 0.2rem 0.4rem 0.3rem 0.4rem;
      text-decoration: none;
      word-break: break-word;

      @include apply-utility('leading', 'tight');
    }

    a:not([class]):hover {
      text-decoration: underline;
    }

    code {
      font-size: 1.2em;
      color: var(--color-theme-primary);
      font-weight: 600;
      margin-left: 0.01ch;
      margin-right: 0.01ch;
    }

    pre > code {
      margin-right: 0;
      border: 1px solid rgba(255, 255, 255, 0.1);
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    blockquote {
      border-left: 0.4rem solid var(--color-theme-primary-glare);
      margin-left: 0;
      padding-left: get-size(500);
      font-style: italic;
      font-size: get-size(600);
      color: var(--color-text);

      p {
        opacity: 0.85;
        padding: get-size(500) 0;
      }
    }

    ol:not([class]),
    ul:not([class]) {
      margin-left: get-size(800);

      li + li {
        margin-top: get-size(300);
      }
    }

    dl {
      > div {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.25em map-get($metrics, 'grid-gap');

        @include media-query('md') {
          grid-template-columns: 24ch 1fr;
          grid-gap: 0.25em get-size(700);
        }
      }

      & + * {
        --flow-space: #{get-size("max")};
      }
    }

    dt {
      text-transform: uppercase;

      @include media-query('md') {
        text-transform: initial;
      }
    }

    figure,
    figure + *,
    pre > code,
    .aspect-ratio,
    .aspect-ratio + *,
    img,
    img + *,
    video {
      --flow-space: #{get-size('max') * 2};
    }

    figure,
    pre > code,
    .asect-ratio,
    video {
      width: 100vw;
      max-width: map-get($metrics, 'wrap-inner-max-width');
      margin-left: calc(50% - 0.25em); /*changing this value to 47% removes the horizontal scrollbar once the viewport is < 930px */
      transform: translateX(-50%); /* changing this value to 49% allows for the suggestion above to also eliminate the horizontal scroll. */
      position: relative;

      &.full-bleed {
        max-width: initial;
      }
    }

    .has-border img {
      border: 2px solid;
    }

    figure:not(.full-bleed):not(.full-length) .portrait {
      margin-left: 50%;
      transform: translateX(-50%);
      max-height: 100vh;
      object-fit: contain;
    }

    figure img {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    figcaption {
      font-size: get-size('base');
      max-width: map-get($metrics, 'wrap-inner-max-width');
      margin: (get-size(500) / 2) auto 0 auto;
      padding: 0 get-size(500);
      
      @include apply-utility('leading', 'tight');

      > span {
        font-style: italic;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;

      & + * {
        --flow-space: #{get-size("max")};
      }
    }

    table:not(.survey) {
      font-size: get-size(500);

      td {
        // padding: get-size(300);
        padding-top: get-size(300) / 2;
        padding-right: get-size(500);
        padding-bottom: get-size(300) / 2;

        &:first-child {
          text-transform: uppercase;
          white-space: nowrap;
        }
      }
    }

    // ensure entries align to the top of their cell
    tbody {
      vertical-align: top;

      th {
        padding-bottom: get-size(700);
        padding-right: get-size(900);
      }
    }

    pre > code {
      display: block;
      background: var(--color-dark);
      padding: get-size(700);
      font-size: get-size(500);
    }
  }

  &__footer {
    --flow-space: #{get-size(900)};

    flex-direction: column;
    letter-spacing: initial;
    color: var(--color-text);

    a {
      color: inherit;
      text-decoration: none;
      box-shadow: none;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-all;

      &:hover {
        // text-decoration: underline;
        box-shadow: 0px 2px 0 var(--color-text);
      }
    }
  }
}

// quick and dirty hack for custom dl spacing on small screen
.md\:gap-top-500 {
  margin-top: 0;

  @include media-query('md') {
    margin-top: get-size(500);
  }
}