/**
 * AUTO GRID UTILITY
 * Set the minimum item size with `--auto-grid-min-size` and you'll
 * get a fully responsive grid with no media queries.
 */

// Config items
$auto-grid__min-size: 14rem;
$auto-grid__enabled: get-config-value('auto-grid', 'utilities');
$auto-grid__prefix: $psf-util-prefix;
$auto-grid__block: 'auto-grid';

// Block starts if enabled in your `$psf-config`
@if ($auto-grid__enabled == 'on') {
  #{ '.' + $auto-grid__prefix + $auto-grid__block } {
    --grid-col-gap: #{map-get($metrics, 'grid-gap')};
    
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size, $auto-grid__min-size), 1fr));
    padding: 0;
    grid-gap: var(--grid-col-gap);
    align-items: end;

    figure {
      @include media-query('md') {
        width: initial;
        margin-left: 0;
        transform: initial;
      }
    }
  }

  #{ '.' + $auto-grid__prefix + $auto-grid__block + '--lg' } {
    --auto-grid-min-size: 24rem; //#{$auto-grid__min-size * 2};
    --grid-col-gap: #{get-size("max")};
  }
}