// Config items
$slider__slide-gutter: get-size('max');
$slider__slide-height: calc(60vh - #{get-size(600)} * 2);
$slider__slide-height--lg: calc(100vh - #{get-size(600)} * 2);

.slider {
  --slide-height: #{$slider__slide-height};

  @include media-query('lg') {
    --slide-height: #{$slider__slide-height--lg};
  }

  &__slide {
    display: block;
    height: var(--slide-height);
    margin-right: $slider__slide-gutter;
    width: auto;
    max-width: initial;
  }
}

.flickity {
  &-enabled {
    position: relative;

    &:focus {
      outline: none;
    }

    &.is-draggable {
      -webkit-tap-highlight-color: transparent;
      user-select: none;

      .flickity-viewport {
        cursor: move;
        cursor: -webkit-grab;
        cursor: grab;

        &.is-pointer-down {
          cursor: -webkit-grabbing;
          cursor: grabbing;
        }
      }
    }
  }

  &-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  &-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &-button {
    background: transparent;
    border: none;
    color: var(--color-text);

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--color-highlight);
    }

    &:disabled {
      opacity: 0.3;
      cursor: auto;
      /* prevent disabled button from capturing pointer up event. #716 */
      pointer-events: none;
    }

    &-icon {
      fill: currentColor;
    }
  }

  &-prev-next-button {
    width: get-size(900);
    height: get-size(900);
    border-radius: 50%;
    transform: translateY(-50%);

    &.previous {
      left: get-size(300);
    }

    &.next {
      right: get-size(300);
    }

    .flickity-button-icon {
      position: absolute;
      left: 20%;
      top: 20%;
      width: 60%;
      height: 60%;
    }
  }

  &-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;

    .dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 8px;
      background: #333;
      border-radius: 50%;
      opacity: 0.25;
      cursor: pointer;

      &.is-selected {
        opacity: 1;
      }
    }
  }
}
