// Hide skip link visually by default
.skip-link:not(:focus) {
    @extend %visually-hidden;
}

.skip-link:focus {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    padding: get-size(300) get-size(500) get-size('base') get-size(500);
    background-color: var(--color-action-bg);
    color: var(--color-action-text);
    line-height: 1;
    text-decoration: none;
    font-weight: 700;
}