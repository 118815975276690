/**
* WRAPPER UTILITY
* A handy container util that sets a width and centers itself, using auto margin.
* You can configure the max width, gutter, class prefix and even the block name.
* Default selector: '.psf-wrapper'.
*/

// Config items
$wrapper__gutter: get-size(500);
$wrapper__enabled: get-config-value('wrapper', 'utilities');
$wrapper__prefix: $psf-util-prefix;
$wrapper__block: 'wrapper';

// Block starts if enabled in your `$config`
@if ($wrapper__enabled == 'on') {
  #{ '.' + $wrapper__prefix + $wrapper__block } {
    --wrapper-gutter: #{$wrapper__gutter};

    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--wrapper-gutter);
  }
}