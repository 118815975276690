.aspect-ratio {
  aspect-ratio: 16/9;

  @supports not (aspect-ratio: 16/9) {
    position: relative;
    padding-top: 56.25%;
  }

  > * {
    width: 100%;
    height: 100%;

    @supports not (aspect-ratio: 16/9) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--variable {
    .aspect-ratio {
      aspect-ratio: 4/3;

      @supports not (aspect-ratio: 4/3) {
        position: relative;
        padding-top: 75%;
      }

      @include media-query('md') {
        aspect-ratio: 16/9;

        @supports not (aspect-ratio: 4/3) {
          padding-top: 56.25%;
        }
      }
    }
  }
}