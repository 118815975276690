:root {
  // Pull the tokens and generate custom props
  @each $color in $psf-colors {
      #{'--color-' + nth($color, 1)}: #{nth($color, 2)};
  }

  // Set theme aliases
  --color-mode: 'light';
  --color-bg: #{get-color('light')};
  --color-bg-rgb: #{hexToRGB(get-color('light'))};
  --color-bg-glare: #{get-color('light')};
  --color-text: #{get-color('dark')};
  --color-text-rgb: #{hexToRGB(get-color('dark'))};
  --color-text-glare: #{get-color('dark')};
  --color-selection-text: #{get-color('light')};
  --color-selection-bg: #{get-color('dark')};
  --color-stroke: #{get-color('mid')};
  --color-action-bg: #{get-color('primary')};
  --color-action-text: #{get-color('light')};
  --color-theme-primary: #{get-color('primary')};
  --color-theme-primary-glare: #{get-color('primary-glare')};
  --color-theme-highlight: #{get-color('highlight')};
  --color-theme-highlight-block: #{get-color('highlight')};

  --arrow-head-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.12 28.48'%3E%3Cpath d='M27 27.7A33.66 33.66 0 000 14.24 33.66 33.66 0 0027 .78C23.08 8.95 16.85 14.24 9.83 14.24c7 0 13.25 5.29 17.17 13.46'/%3E%3Cpath d='M27 27.7A33.66 33.66 0 000 14.24 33.66 33.66 0 0027 .78C23.08 8.95 16.85 14.24 9.83 14.24 16.8 14.24 23 19.52 27 27.7z' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2.6' fill='none' stroke='%23333'/%3E%3C/svg%3E");
  --arrow-head-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.12 28.48'%3E%3Cpath d='M1.17 27.7a33.66 33.66 0 0127-13.46A33.66 33.66 0 011.17.78c3.92 8.17 10.15 13.46 17.17 13.46-7 0-13.25 5.29-17.17 13.46'/%3E%3Cpath d='M1.17 27.7a33.66 33.66 0 0127-13.46A33.66 33.66 0 011.17.78c3.92 8.17 10.15 13.46 17.17 13.46-7.02 0-13.25 5.28-17.17 13.46z' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2.6' fill='none' stroke='%23333'/%3E%3C/svg%3E");
  --arrow-head-left-light: url("data:image/svg+xml,%3Csvg id='Laag_1' data-name='Laag 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.68 27.82'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:none;stroke:%23000;%7D.cls-1%7Bstroke-width:2.99px;%7D.cls-2%7Bstroke-linecap:square;stroke-miterlimit:10;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrowleft-light%3C/title%3E%3Cpath class='cls-1' d='M9.15,14.24' transform='translate(0 -0.33)'/%3E%3Cpath d='M27,27.7A33.66,33.66,0,0,0,0,14.24,33.66,33.66,0,0,0,27,.78C23.08,8.95,16.85,14.24,9.83,14.24c7,0,13.25,5.29,17.17,13.46' transform='translate(0 -0.33)'/%3E%3Cpath class='cls-2' d='M27,27.7A33.66,33.66,0,0,0,0,14.24,33.66,33.66,0,0,0,27,.78C23.08,8.95,16.85,14.24,9.83,14.24,16.8,14.24,23,19.52,27,27.7Z' transform='translate(0 -0.33)'/%3E%3C/svg%3E");
  --arrow-head-right-light: url("data:image/svg+xml,%3Csvg id='Laag_1' data-name='Laag 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.68 27.82'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:none;stroke:%23000;%7D.cls-1%7Bstroke-width:2.99px;%7D.cls-2%7Bstroke-linecap:square;stroke-miterlimit:10;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrowright-light%3C/title%3E%3Cpath class='cls-1' d='M14.06,14.24' transform='translate(-0.49 -0.33)'/%3E%3Cpath d='M1.17,27.7a33.66,33.66,0,0,1,27-13.46A33.66,33.66,0,0,1,1.17.78C5.09,8.95,11.32,14.24,18.34,14.24c-7,0-13.25,5.29-17.17,13.46' transform='translate(-0.49 -0.33)'/%3E%3Cpath class='cls-2' d='M1.17,27.7a33.66,33.66,0,0,1,27-13.46A33.66,33.66,0,0,1,1.17.78C5.09,8.95,11.32,14.24,18.34,14.24,11.32,14.24,5.09,19.52,1.17,27.7Z' transform='translate(-0.49 -0.33)'/%3E%3C/svg%3E");
}

.bg-dark {
  --color-bg: #{get-color('dark')};
  --color-text: #{get-color('light')};

  --arrow-head-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.12 28.48'%3E%3Cpath d='M27 27.7A33.66 33.66 0 000 14.24 33.66 33.66 0 0027 .78C23.08 8.95 16.85 14.24 9.83 14.24c7 0 13.25 5.29 17.17 13.46'/%3E%3Cpath d='M27 27.7A33.66 33.66 0 000 14.24 33.66 33.66 0 0027 .78C23.08 8.95 16.85 14.24 9.83 14.24 16.8 14.24 23 19.52 27 27.7z' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2.6' fill='none' stroke='%23fff'/%3E%3C/svg%3E");
  --arrow-head-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.12 28.48'%3E%3Cpath d='M1.17 27.7a33.66 33.66 0 0127-13.46A33.66 33.66 0 011.17.78c3.92 8.17 10.15 13.46 17.17 13.46-7 0-13.25 5.29-17.17 13.46'/%3E%3Cpath d='M1.17 27.7a33.66 33.66 0 0127-13.46A33.66 33.66 0 011.17.78c3.92 8.17 10.15 13.46 17.17 13.46-7.02 0-13.25 5.28-17.17 13.46z' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2.6' fill='none' stroke='%23fff'/%3E%3C/svg%3E");
  --arrow-head-left-light: url("data:image/svg+xml,%3Csvg id='Laag_1' data-name='Laag 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.68 27.82'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:none;stroke:%23fff;%7D.cls-1%7Bstroke-width:2.99px;%7D.cls-2%7Bstroke-linecap:square;stroke-miterlimit:10;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrowleft-light%3C/title%3E%3Cpath class='cls-1' d='M9.15,14.24' transform='translate(0 -0.33)'/%3E%3Cpath d='M27,27.7A33.66,33.66,0,0,0,0,14.24,33.66,33.66,0,0,0,27,.78C23.08,8.95,16.85,14.24,9.83,14.24c7,0,13.25,5.29,17.17,13.46' transform='translate(0 -0.33)'/%3E%3Cpath class='cls-2' d='M27,27.7A33.66,33.66,0,0,0,0,14.24,33.66,33.66,0,0,0,27,.78C23.08,8.95,16.85,14.24,9.83,14.24,16.8,14.24,23,19.52,27,27.7Z' transform='translate(0 -0.33)'/%3E%3C/svg%3E");
  --arrow-head-right-light: url("data:image/svg+xml,%3Csvg id='Laag_1' data-name='Laag 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.68 27.82'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:none;stroke:%23fff;%7D.cls-1%7Bstroke-width:2.99px;%7D.cls-2%7Bstroke-linecap:square;stroke-miterlimit:10;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrowright-light%3C/title%3E%3Cpath class='cls-1' d='M14.06,14.24' transform='translate(-0.49 -0.33)'/%3E%3Cpath d='M1.17,27.7a33.66,33.66,0,0,1,27-13.46A33.66,33.66,0,0,1,1.17.78C5.09,8.95,11.32,14.24,18.34,14.24c-7,0-13.25,5.29-17.17,13.46' transform='translate(-0.49 -0.33)'/%3E%3Cpath class='cls-2' d='M1.17,27.7a33.66,33.66,0,0,1,27-13.46A33.66,33.66,0,0,1,1.17.78C5.09,8.95,11.32,14.24,18.34,14.24,11.32,14.24,5.09,19.52,1.17,27.7Z' transform='translate(-0.49 -0.33)'/%3E%3C/svg%3E");
}

// @include dark-mode() {
//   --color-bg: #{get-color('dark-slate')};
//   --color-bg-glare: #{get-color('slate')};
//   --color-text: #{get-color('light')};
//   --color-selection-text: #{get-color('dark')};
//   --color-selection-bg: #{get-color('light')};
//   --color-stroke: #{get-color('slate')};
//   --color-theme-primary: #{lighten(get-color('primary'), 50%)};
//   --color-theme-primary-glare: #{lighten(get-color('primary-glare'), 50%)};
//   --color-action-bg: var(--color-theme-primary-glare);
//   --color-action-text: #{get-color('dark')};
//   --color-theme-highlight: #{get-color('highlight')};
//   --color-theme-highlight-block: #{get-color('slate')};
//   --color-theme-feature-text: #{get-color('highlight')};

//   --arrow-head-left: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.12 28.48'%3E%3Cpath d='M27 27.7A33.66 33.66 0 000 14.24 33.66 33.66 0 0027 .78C23.08 8.95 16.85 14.24 9.83 14.24c7 0 13.25 5.29 17.17 13.46'/%3E%3Cpath d='M27 27.7A33.66 33.66 0 000 14.24 33.66 33.66 0 0027 .78C23.08 8.95 16.85 14.24 9.83 14.24 16.8 14.24 23 19.52 27 27.7z' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2.6' fill='none' stroke='%23fff'/%3E%3C/svg%3E");
//   --arrow-head-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.12 28.48'%3E%3Cpath d='M1.17 27.7a33.66 33.66 0 0127-13.46A33.66 33.66 0 011.17.78c3.92 8.17 10.15 13.46 17.17 13.46-7 0-13.25 5.29-17.17 13.46'/%3E%3Cpath d='M1.17 27.7a33.66 33.66 0 0127-13.46A33.66 33.66 0 011.17.78c3.92 8.17 10.15 13.46 17.17 13.46-7.02 0-13.25 5.28-17.17 13.46z' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2.6' fill='none' stroke='%23fff'/%3E%3C/svg%3E");
//   --arrow-head-left-light: url("data:image/svg+xml,%3Csvg id='Laag_1' data-name='Laag 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.68 27.82'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:none;stroke:%23fff;%7D.cls-1%7Bstroke-width:2.99px;%7D.cls-2%7Bstroke-linecap:square;stroke-miterlimit:10;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrowleft-light%3C/title%3E%3Cpath class='cls-1' d='M9.15,14.24' transform='translate(0 -0.33)'/%3E%3Cpath d='M27,27.7A33.66,33.66,0,0,0,0,14.24,33.66,33.66,0,0,0,27,.78C23.08,8.95,16.85,14.24,9.83,14.24c7,0,13.25,5.29,17.17,13.46' transform='translate(0 -0.33)'/%3E%3Cpath class='cls-2' d='M27,27.7A33.66,33.66,0,0,0,0,14.24,33.66,33.66,0,0,0,27,.78C23.08,8.95,16.85,14.24,9.83,14.24,16.8,14.24,23,19.52,27,27.7Z' transform='translate(0 -0.33)'/%3E%3C/svg%3E");
//   --arrow-head-right-light: url("data:image/svg+xml,%3Csvg id='Laag_1' data-name='Laag 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.68 27.82'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:none;stroke:%23fff;%7D.cls-1%7Bstroke-width:2.99px;%7D.cls-2%7Bstroke-linecap:square;stroke-miterlimit:10;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrowright-light%3C/title%3E%3Cpath class='cls-1' d='M14.06,14.24' transform='translate(-0.49 -0.33)'/%3E%3Cpath d='M1.17,27.7a33.66,33.66,0,0,1,27-13.46A33.66,33.66,0,0,1,1.17.78C5.09,8.95,11.32,14.24,18.34,14.24c-7,0-13.25,5.29-17.17,13.46' transform='translate(-0.49 -0.33)'/%3E%3Cpath class='cls-2' d='M1.17,27.7a33.66,33.66,0,0,1,27-13.46A33.66,33.66,0,0,1,1.17.78C5.09,8.95,11.32,14.24,18.34,14.24,11.32,14.24,5.09,19.52,1.17,27.7Z' transform='translate(-0.49 -0.33)'/%3E%3C/svg%3E");
//   // --blend-mode-body: multiply;
// }

body {
  color: var(--color-text);
  background-color: var(--color-bg);
  position: relative;
  min-height: 100vh;
}

.page {
  &__inner {
    background-image: url(../images/noise-tile.jpg);
  }
}

::selection {
  color: var(--color-selection-text);
  background-color: var(--color-selection-bg);
}