// Config items
$text-columns: 3;
$text-columns__gap: get-size('max');
$text-columns__width: map-get($metrics, 'text-column-width');
$text-columns__enabled: get-config-value('text-columns', 'utilities');

// Block starts if enabled in your `$psf-config`
@if ($text-columns__enabled == 'on') {
  .text-columns {
    --text-columns: #{$text-columns};
    --text-columns-gap: #{$text-columns__gap};
    --text-columns-width: #{$text-columns__width};

    column-count: var(--text-columns);
    column-gap: var(--text-columns-gap);
    column-width: var(--text-columns-width);
    column-rule: 1px solid;
  }
}