.event-meta {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: currentColor;
    text-decoration: none;
    box-shadow: 0px 2px 0 var(--color-text);

    &:hover {
      box-shadow: none;
    }
  }
}