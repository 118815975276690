// Config items
$inner-wrapper__min-width: map-get($metrics, 'wrap-inner-min-width');
$inner-wrapper__ideal-width: map-get($metrics, 'wrap-inner-ideal-width');
$inner-wrapper__max-width: map-get($metrics, 'wrap-inner-max-width');
$inner-wrapper__gutter: get-size(500);
$inner-wrapper__gutter--md: get-size(800);

.inner-wrapper {
  --inner-wrapper-gutter: #{$inner-wrapper__gutter};

  @include media-query('md') {
    --inner-wrapper-gutter: #{$inner-wrapper__gutter--md};
  }

  width: $inner-wrapper__ideal-width;
  width: clamp(#{$inner-wrapper__min-width}, #{$inner-wrapper__ideal-width}, #{$inner-wrapper__max-width});
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--inner-wrapper-gutter);
  padding-right: var(--inner-wrapper-gutter);
}