// Config items
$btn__margin: get-size(300);
$btn__margin--md: get-size(600);
$btn__size: get-size(500);
$btn__size--md: get-size(800);
$btn__border-radius: get-border-radius("circle");
$btn__symbol-thickness: 0.3rem;

.modal {
  --btn-size: #{$btn__size};
  --btn-position: #{$btn__margin};

  @include media-query('md') {
    --btn-size: #{$btn__size--md};
    --btn-position: #{$btn__margin--md};
  }

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  
  will-change: opacity;
  background-color: var(--color-bg);
  // background-color: rgba(var(--color-bg-rgb), 0.9);

  &__close {
    position: fixed;
    top: var(--btn-position);
    right: var(--btn-position);
    color: inherit;
    text-decoration: none;
    border-radius: $btn__border-radius;
  }

  &__x {
    width: var(--btn-size);
    height: var(--btn-size);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: $btn__symbol-thickness;
      background-color: var(--color-text);
      border-radius: 1px;
      position: absolute;
    }

    &::after {
      transform: rotate(90deg);
    }
  }
}

/**
 * Environment requirements
 * The style below are required for the modal animation
 */
html:not(.holding-page) {
  height: 100%;
}

body{
  :not(.holding-page) & {
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    transition: opacity 1s ease-out;
  }
}

.page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  will-change: opacity;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
}