.nav {
    &__list {
        overflow-x: auto;
        list-style: none;

        // Add padding and neg margin to allow focus style visibility
        padding: 0.5rem;
        margin: -0.5rem;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        > * + * {
            margin-left: get-size(500);
        }
    }

    &__item {
        padding: get-size(300) 0;
        flex-shrink: 0;

        a {
            color: currentColor;

            @include apply-utility('weight', 'mid');

            &:not(:hover) {
                text-decoration: none;
            }
        }
    }
}