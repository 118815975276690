/**
 * MEDIA QUERY MIXIN
 * Pass in the key of one of your breakpoints set in `$psf-config['breakpoints']`
 * and this mixin will generate the @media query with your configured value.
 *
 * @param {string} $key - The key of your configured breakpoint
 */
@mixin media-query($key) {
    $breakpoints: map-get($psf-config, 'breakpoints');
    $matched-breakpoint: map-get($breakpoints, $key);

    @if not $matched-breakpoint {
        @warn #{ 'Breakpoint, ‘' + $key + '’ not found in `$psf-config`. Make sure it’s set in the ‘breakpoints’ section.' };
    } @else {
        @media #{ $matched-breakpoint } {
            @content;
        }
    }
}