body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-display: swap;
  text-rendering: optimizeSpeed;
}

main {
  flex: 1 0 auto;
  &:focus {
    outline: none;
  }
}

hr {
  display: block;
  height: 1px;
  max-width: 500px;
  background: var(--color-stroke);
  border: none;
  margin: get-size(900) auto;
}

// For when metric attributes are added to img elements
img {
  height: auto;
}

iframe {
  border: 0;
}

// Give a little space to internal anchors
[id] {
  scroll-margin-top: var(--scroll-margin, 2ex);
}

:focus {
  outline: 1px solid var(--color-theme-primary-glare);
  outline-offset: 0.25rem;
}

[id] {
    scroll-margin-top: var(--scroll-margin, 2ex);
}

[data-style="tabular"] {
  font-variant-numeric: tabular-nums;
}