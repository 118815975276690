// Config items
$post-list__gutter: get-size(900);
$post-list__flow-space: get-size("max");
$post-list__item-flow-space: get-size(700);

.post-list {
  --flow-space: #{$post-list__flow-space};

  // &__items {
  //   list-style: none;
  //   padding-left: 0;
  //   padding-inline-start: 0;
  // }

  &__item {
    --flow-space: #{$post-list__item-flow-space};
  }

  // &__link {
  //   &,
  //   &:visited {
  //     color: var(--color-theme-primary);
  //     text-decoration: none;
  //   }

  //   &:hover {
  //     text-decoration: underline;
  //   }
  // }

  .wrapper {
    --wrapper-gutter: #{$post-list__gutter}; 
  }
}